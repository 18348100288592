<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="订单号">
                <a-input v-model="queryParam.orderNo" placeholder="请输入" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="退款编号">
                <a-input v-model="queryParam.refundSn" placeholder="请输入" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="用户手机号">
                <a-input v-model="queryParam.userPhone" placeholder="请输入" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="退款状态">
                <a-select v-model="queryParam.status" show-search placeholder="请选择">
                  <a-select-option v-for="(item,key) in [{name:'待处理',id:0},{name:'退款成功',id:1},{name:'退款已处理',id:2},{name:'退款关闭',id:3},{name:'退款失败',id:4}]" :key="key" :value="item.id">{{ item.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <!-- <a-col :md="6" :sm="24">
              <a-form-item label="创建时间">
                <a-range-picker style="width: 100%" v-model="dateRange" valueFormat="YYYY-MM-DD" format="YYYY-MM-DD" allow-clear/>
              </a-form-item>
            </a-col> -->
            <a-col :md="!advanced && 4 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <!-- <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['global:team:add']">
          <a-icon type="plus" />新增
        </a-button>
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['global:team:edit']">
          <a-icon type="edit" />修改
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['global:team:remove']">
          <a-icon type="delete" />删除
        </a-button> -->
        <!-- <a-button type="primary" @click="handleExport" v-hasPermi="['global:team:export']">
          <a-icon type="download" />导出
        </a-button>
        <a-button type="dashed" :loading="refreshing" @click="handleRefreshCache" v-hasPermi="['global:team:remove']">
          <a-icon type="redo" />刷新缓存
        </a-button> -->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 详情 -->
      <info
        ref="info"
        :typeOptions="dict.type['sys_yes_no']"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :scroll="{ x: 1200 }"
        :bordered="tableBordered">
        <span slot="type" slot-scope="text, record">
          <a-tag color="blue" v-if="record.type == 0">未退款</a-tag>
          <a-tag color="orange" v-else-if="record.type == 1">部分退款</a-tag>
          <a-tag color="red" v-else-if="record.type == 2">全部退款</a-tag>
        </span>
        <span slot="status" slot-scope="text, record">
          <a-tag color="orange" v-if="record.status == 0">待处理</a-tag>
          <a-tag color="green" v-if="record.status == 1">退款成功</a-tag>
          <a-tag color="blue" v-else-if="record.status == 2">退款已处理</a-tag>
          <a-tag color="red" v-else-if="record.status == 3">退款关闭</a-tag>
          <a-tag color="cyan" v-else-if="record.status == 4">退款失败</a-tag>
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a @click="$refs.info.info(record.id)" v-hasPermi="['wisdom:orderrefund:query']">
            <a-icon type="eye" />详情
          </a>
          <a-divider type="vertical" v-hasPermi="['wisdom:orderrefund:add']" v-if="(record.type == 0 && record.status == 0) || (record.type == 0 && record.status == 4)"/>
          <a @click="handle(record.id,1)" v-hasPermi="['wisdom:orderrefund:add']" v-if="(record.type == 0 && record.status == 0) || (record.type == 0 && record.status == 4)">
            <a-icon type="edit" />退款
          </a>
          <a-divider type="vertical" v-hasPermi="['wisdom:orderrefund:edit']" v-if="record.status == 0 && record.status == 2"/>
          <a @click="handle(record.id,2)" v-hasPermi="['wisdom:orderrefund:edit']" v-if="record.status == 0 && record.status == 2">
            <a-icon type="edit" />取消退款
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>

import { orderRefundPage, orderRefundAdd, orderRefundEdit } from '@/api/pages/payment-refund'
import Info from './modules/refund-info'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'Config',
  components: {
    Info
  },
  mixins: [tableMixin],
  dicts: ['sys_yes_no'],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      refreshing: false,
      total: 0,
      // 日期范围
      dateRange: [],
      queryParam: {
        pageNum: 1,
        pageSize: 10,
        userName: null
      },
      columns: [
        {
          title: '订单号',
          width: 120,
          dataIndex: 'orderNo',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '水表编号',
          width: 160,
          dataIndex: 'devNeea',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '用户手机号',
          width: 120,
          dataIndex: 'userPhoneYh',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '退款金额',
          width: 100,
          dataIndex: 'refundAmount',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '退款编号',
          width: 200,
          dataIndex: 'refundSn',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '退款类型',
          width: 120,
          dataIndex: 'type',
          scopedSlots: { customRender: 'type' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '退款状态',
          width: 120,
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '申请时间',
          dataIndex: 'applyTime',
          scopedSlots: { customRender: 'applyTime' },
          ellipsis: true,
          width: 160,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 220,
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询参数列表 */
    getList () {
      this.loading = true
      orderRefundPage(this.queryParam).then(response => {
          this.list = response.data.records
          this.total = response.data.total
          this.loading = false
        }
      )
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.dateRange = []
      this.queryParam = {
        pageNum: 1,
        pageSize: 10,
        userName: null
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },

    handle (id, type) {
      var that = this
      if (type === 1) {
        this.$confirm({
          title: '确认退款所选中项?',
          content: '确认删除所选中数据?',
          onOk () {
            return orderRefundAdd({ id })
              .then(() => {
                that.onSelectChange([], [])
                that.getList()
                that.$message.success(
                  '操作成功',
                  3
                )
            })
          },
          onCancel () {}
        })
      } else if (type === 2) {
        this.$confirm({
          title: '确认取消退款所选中项?',
          content: '确认删除所选中数据?',
          onOk () {
            return orderRefundEdit({ id })
              .then(() => {
                that.onSelectChange([], [])
                that.getList()
                that.$message.success(
                  '操作成功',
                  3
                )
            })
          },
          onCancel () {}
        })
      }
    }
  }
}
</script>
